import React, { useState } from "react";
import styled from "styled-components";
import NumericInput from "react-numeric-input";

const Container = styled.div`
  border: 4px solid black;
  font-family: "Helvetica Neue";
  margin: 0.5rem;
  padding: 0 0.5rem;
  width: 300px;
`;

const Heading = styled.h1`
  border-bottom: 2px solid black;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  padding: 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 0.2rem 0;

  &:last-child {
    border-bottom: 0;
  }
`;
const Percent = styled.span``;

const CaloriesLabel = styled.span`
  font-size: 24px;
  font-weight: bold;
`;
const Calories = styled.span`
  font-weight: bold;
  font-size: 2rem;
`;
const Label = styled.label`
  font-weight: bold;
`;
const InputContainer = styled.div`
  & .react-numeric-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    & input {
      border: 0 !important;
      max-width: 60px;
      height: 100%;
      text-align: center;
    }

    & b {
      display: none;
    }
  }
`;

const TotalContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid black;
`;

const TotalLabel = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CarbContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const PERatio = () => {
  const [protein, setProtein] = useState(62);
  const [fat, setFat] = useState(48);
  const [carbohydrates, setCarbohydrates] = useState(0);
  const [fiber, setFiber] = useState(0);

  // Protein & Carbs contain 4 calories per gram
  // Fat contains 9 calories per gram
  let proteinCalories = 4 * protein;
  let fatCalories = 9 * fat;
  let carbsCalories = 4 * (carbohydrates - fiber);
  let totalCalories = Math.round(proteinCalories + fatCalories + carbsCalories);
  const emptyCalories = totalCalories === 0;
  let proteinPercent: string | number = emptyCalories
    ? 0
    : (proteinCalories / totalCalories) * 100;
  let fatPercent: string | number = emptyCalories
    ? 0
    : (fatCalories / totalCalories) * 100;
  let carbsPercent: string | number = emptyCalories
    ? 0
    : (carbsCalories / totalCalories) * 100;

  proteinPercent = Math.round(proteinPercent * 10) / 10;
  fatPercent = Math.round(fatPercent * 10) / 10 + "%";
  carbsPercent = Math.round(carbsPercent * 10) / 10 + "%";

  let ratio = protein / (fat + carbohydrates - fiber) || 0;
  ratio = Math.round(ratio * 100) / 100;

  let nutritionalVector = (Math.atan(ratio) * 180) / Math.PI;
  nutritionalVector = Math.round(nutritionalVector * 10) / 10;

  return (
    <Container>
      <Heading>Protein Energy Ratio</Heading>
      <TotalContainer>
        <TotalLabel>
          <span>Amount per serving</span>
          <CaloriesLabel>Calories</CaloriesLabel>
        </TotalLabel>
        <Calories>{totalCalories}</Calories>
      </TotalContainer>
      <List>
        <ListItem>
          <Row>
            <Label>Total Fat</Label>{" "}
            <InputContainer>
              <NumericInput
                min={0}
                value={fat}
                type="number"
                onChange={val => setFat(val || 0)}
                format={val => `${val}g`}
              />
            </InputContainer>
          </Row>
          <Percent>{fatPercent}</Percent>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <CarbContainer>
            <Row>
              <Label>Total Carbohydrate</Label>{" "}
              <InputContainer>
                <NumericInput
                  min={0}
                  value={carbohydrates}
                  type="number"
                  onChange={val => setCarbohydrates(val || 0)}
                  format={val => `${val}g`}
                />
              </InputContainer>
            </Row>
            <Row>
              <label>Dietary Fiber</label>
              <InputContainer>
                <NumericInput
                  min={0}
                  max={carbohydrates}
                  value={fiber}
                  type="number"
                  onChange={val => setFiber(val || 0)}
                  format={val => `${val}g`}
                />
              </InputContainer>
            </Row>
          </CarbContainer>
          <Percent>{carbsPercent}</Percent>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Row>
            <Label>Protein</Label>
            <InputContainer>
              <NumericInput
                min={0}
                value={protein}
                type="number"
                onChange={val => setProtein(val || 0)}
                format={val => `${val}g`}
              />
            </InputContainer>
          </Row>
          <Percent>{carbsPercent}</Percent>
        </ListItem>
        <ListItem>
          <Label>Ratio</Label> {ratio}
        </ListItem>
      </List>
    </Container>
  );
};

// g -> grams
export function calcProteinEnergyRatio(
  protein: number, // g
  fat: number, // g
  carbohydrates: number, // g
  fiber: number // g
) {
  // Protein & Carbs contain 4 calories per gram
  // Fat contains 9 calories per gram
  let proteinCalories = 4 * protein;
  let fatCalories = 9 * fat;
  let carbsCalories = 4 * (carbohydrates - fiber);
  let totalCalories = Math.round(proteinCalories + fatCalories + carbsCalories);

  let proteinPercent: string | number = (proteinCalories / totalCalories) * 100;
  let fatPercent: string | number = (fatCalories / totalCalories) * 100;
  let carbsPercent: string | number = (carbsCalories / totalCalories) * 100;

  proteinPercent = Math.round(proteinPercent * 10) / 10 + "%";
  fatPercent = Math.round(fatPercent * 10) / 10 + "%";
  carbsPercent = Math.round(carbsPercent * 10) / 10 + "%";

  let ratio = protein / (fat + carbohydrates - fiber);

  ratio = Math.round(ratio * 100) / 100;

  let nutritionalVector = (Math.atan(ratio) * 180) / Math.PI;

  nutritionalVector = Math.round(nutritionalVector * 10) / 10;

  return {
    totalCalories,
    proteinPercent,
    fatPercent,
    carbsPercent,
    ratio,
    nutritionalVector
  };
}
